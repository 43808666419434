import logo from './logo.png';
import './App.css';
import { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

function App() {

  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setIsMobile(!isMobile);
  };

  return (
    <div id="#home" className="landing-page">
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} width={50} height={50} alt="logo" />
        </div>
        <ul className={isMobile ? "nav-links-mobile" : "nav-links"} onClick={() => setIsMobile(false)}>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#community">Community</a></li>
          <li><a href="#howto">How To Buy</a></li>
        </ul>
        <div className="menu-icon" onClick={toggleMenu}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </nav>

      <div className="logo-container">
        <img src={logo} className="app-logo bouce" alt="logo" />
        <h2 className='tagline'>From the baby bottle to the moon... 🍼 🌙</h2>
      </div>

      <div id="about" className='section'>
        <h2 className='title'>About</h2>
        <p className='text'>Whether you're a crypto newbie or a seasoned pro, DripBaby is here to make sure your wallet stays fresh and your memes stay lit. Join the crew, embrace the chill, and let’s ride the wave together. From the baby bottle to the moon — it’s time to get your drip on with DripBaby Coin! 💧 👶 ✨</p>
      </div>

      <div id="community" className='section'>
        <h2 className='title'>Community</h2>
        <p className='text'>The DripBaby community is all about unity, fun, and growth — and we're aiming to be everywhere. We’re not just a coin, we’re a movement, a culture, and a place where style and crypto meet. As DripBaby spreads across the web, we’re bringing together the people, the vibes and the drip. 💧 👶 🌍</p>
        
      </div>

      <div id="howto" className='section'>
        <h2 className='title'>How To Buy</h2>
        <p className='text'><span className='numspan'>1.</span>Download Phantom Wallet – Available via Google Chrome extension on desktop or mobile.</p>
        <p className='text'><span className='numspan'>2.</span>Buy some SOL – Purchase Solana (SOL) on any exchange and transfer it to your Phantom wallet.</p>
        <p className='text'><span className='numspan'>3.</span>Search for DRIPBABY – In your Phantom wallet, go to the "Swap" section, search for DripBaby by its token name, and select it.</p>
        <p className='text'><span className='numspan'>4.</span>Buy DripBaby – Enter the amount you want to purchase and complete the transaction!.</p>
        <p className='text'>And you're set! Enjoy the drip! 🍼🌙 - be sure to select the correct token!</p>    
      </div>

      <footer className="footer">
        <p>From the baby bottle to the moon 🍼 🌙</p>
        <a 
          href="https://t.me/+VubmkWEOZSwxZGQ8" 
          target="_blank" 
          rel="noopener noreferrer"
          className="footer-link"
        >
          Telegram
        </a>
        <a 
          href="https://x.com/dripbabymeme" 
          target="_blank" 
          rel="noopener noreferrer"
          className="footer-link"
        >
          Twitter X
        </a>
        {/* <a 
          href="https://dexscreener.com/yourdex" 
          target="_blank" 
          rel="noopener noreferrer"
          className="footer-link"
        >
          Dex Screener
        </a> */}
      </footer>
    </div>
  );
}

export default App;